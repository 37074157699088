import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts
import IranSansFont from '@/styles/fonts/iran-sans'
import OpenSansFont from '@/styles/fonts/open-sans'

const ExtendThemeConfig = (dir: Direction) =>
	extendTheme({
		direction: dir,
		colorSchemes: {
			light: {
				palette: {
					mode: 'light',
					primary: { light: '#87d9fc', main: '#10B4F9', dark: '#0b7dae', contrastText: '#fff' },
					secondary: { light: '#6991a1', main: '#064863', dark: '#021c27', contrastText: '#fff' }
				}
			},
			dark: {
				palette: {
					mode: 'dark',
					primary: { light: '#87d9fc', main: '#10B4F9', dark: '#0b7dae', contrastText: '#fff' },
					secondary: { light: '#6991a1', main: '#064863', dark: '#021c27', contrastText: '#fff' }
				}
			}
		},
		...(dir === 'rtl' ? { typography: { fontFamily: IranSansFont.style.fontFamily } } : { typography: { fontFamily: OpenSansFont.style.fontFamily } }),
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		}
	})

export default ExtendThemeConfig
